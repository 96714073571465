import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

export const villageRouters = {
  path: 'village',
  component: () => import('@/views/settings/village/Index'),
  redirect: 'village',
  meta: {
    permissions: [Permissions.backend.setting.village.manage],
    groups: 'village'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/village`,
      name: 'list-village',
      component: () => import('@/views/settings/village/List'),
      meta: {
        permissions: [Permissions.backend.setting.village.list],
        groups: 'village',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជីឈ្មោះភូមិ'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-village',
      component: () => import('@/views/settings/village/Create'),
      meta: {
        permissions: [Permissions.backend.setting.village.create],
        groups: 'village',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Village List',
            name_km: 'បញ្ជីឈ្មោះភូមិ',
            route: { name: 'list-village' }
          },
          {
            name_en: 'Create village',
            name_km: 'បញ្ជីឈ្មោះភូមិ'
          }
        ]
      }
    },
    {
      path: 'edit/:id',
      name: 'edit-village',
      component: () => import('@/views/settings/village/Edit'),
      meta: {
        permissions: [Permissions.backend.setting.village.edit],
        groups: 'village',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Village List',
            name_km: 'បញ្ជីឈ្មោះភូមិ',
            route: { name: 'edit-village' }
          },
          {
            name_en: 'Edit Village',
            name_km: 'កែសំរួលឈ្មោះភូមិ'
          }
        ]
      }
    }
  ]
}
