export const useBaseRouteModules = () => {
  const baseAdminModuleUrl = '/admin/administrations'
  const baseSettingModuleUrl = '/admin/settings'
  const baseAccountModuleUrl = '/admin'
  const basePropertyModuleUrl = '/admin/property'
  const baseLandlordModuleUrl = '/admin/landlord'
  const baseTenantModuleUrl = '/admin/tenant'

  return {
    baseAdminModuleUrl,
    baseSettingModuleUrl,
    baseAccountModuleUrl,
    basePropertyModuleUrl,
    baseLandlordModuleUrl,
    baseTenantModuleUrl
  }
}
