import { userRouters } from '@/routers/administrations/user'
import { roleRouters } from '@/routers/administrations/role'
import { permissionRouters } from '@/routers/administrations/permission'
import Permissions from '@/permissions'

export const administrationRouters = {
  path: 'administration',
  name: 'administration',
  component: () => import('@/views/administration/Index'),
  redirect: 'user',
  meta: {
    permissions: [
      Permissions.backend.administration.manage,
      Permissions.backend.administration.user.manage,
      Permissions.backend.administration.role.manage,
      Permissions.backend.administration.permission.manage
    ]
  },
  children: [userRouters, roleRouters, permissionRouters]
}
