import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseAdminModuleUrl } = useBaseRouteModules()

export const userRouters = {
  path: 'user',
  component: () => import('@/views/administration/user/Index'),
  redirect: 'user',
  meta: {
    permissions: [Permissions.backend.administration.user.manage],
    groups: 'user'
  },
  children: [
    {
      path: `${baseAdminModuleUrl}/user`,
      name: 'list-user',
      component: () => import('@/views/administration/user/List'),
      meta: {
        permissions: [Permissions.backend.administration.user.list],
        groups: 'user',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'User List',
            name_km: 'បញ្ជីអ្នកប្រើប្រាស់'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-user',
      component: () => import('@/views/administration/user/Create'),
      meta: {
        permissions: [Permissions.backend.administration.user.store],
        groups: 'user',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'User List',
            name_km: 'បញ្ជីអ្នកប្រើប្រាស់',
            route: { name: 'list-user' }
          },
          {
            name_en: 'Create User',
            name_km: 'បង្កើតអ្នកប្រើប្រាស់ថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:uuid',
      name: 'edit-user',
      component: () => import('@/views/administration/user/Edit'),
      meta: {
        permissions: [Permissions.backend.administration.user.edit],
        groups: 'user',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'User List',
            name_km: 'បញ្ជីអ្នកប្រើប្រាស់',
            route: { name: 'list-user' }
          },
          {
            name_en: 'Edit User',
            name_km: 'កែប្រែព័ត៌មានអ្នកប្រើប្រាស់'
          }
        ]
      }
    },
    {
      path: 'show/:uuid',
      name: 'show-user',
      component: () => import('@/views/administration/user/Show'),
      meta: {
        permissions: [Permissions.backend.administration.user.show],
        groups: 'user',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'User List',
            name_km: 'បញ្ជីអ្នកប្រើប្រាស់',
            route: { name: 'list-user' }
          },
          {
            name_en: 'Use Detail',
            name_km: 'បង្ហាញព័ត៌មានអ្នកប្រើប្រាស់'
          }
        ]
      }
    },
    {
      path: 'reset-password/:uuid',
      name: 'reset-password',
      component: () => import('@/views/administration/user/ResetPassword'),
      meta: {
        permissions: [Permissions.backend.administration.user.changePassword],
        groups: 'user',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'User List',
            name_km: 'បញ្ជីអ្នកប្រើប្រាស់',
            route: { name: 'list-user' }
          },
          {
            name_en: 'Reset User Password',
            name_km: 'កំណត់ពាក្យសម្ងាត់របស់អ្នកប្រើប្រាស់ឡើងវិញ'
          }
        ]
      }
    }
  ]
}
