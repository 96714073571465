import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseAdminModuleUrl } = useBaseRouteModules()

export const roleRouters = {
  path: 'role',
  component: () => import('@/views/administration/role/Index'),
  redirect: 'role',
  meta: {
    permissions: [Permissions.backend.administration.role.manage],
    groups: 'role'
  },
  children: [
    {
      path: `${baseAdminModuleUrl}/role`,
      name: 'list-role',
      component: () => import('@/views/administration/role/List'),
      meta: {
        permissions: [Permissions.backend.administration.role.list],
        groups: 'role',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជីតួនាទី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-role',
      component: () => import('@/views/administration/role/Create'),
      meta: {
        permissions: [Permissions.backend.administration.role.store],
        groups: 'role',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី',
            route: { name: 'list-role' }
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'show/:id',
      name: 'show-role',
      component: () => import('@/views/administration/role/Create'),
      meta: {
        permissions: [Permissions.backend.administration.role.show],
        groups: 'role',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជីតួនាទី',
            route: { name: 'list-role' }
          },
          {
            name_en: 'Role',
            name_km: 'លម្អិតព័ត៌មាន'
          }
        ]
      }
    },
    {
      path: 'edit/:id',
      name: 'edit-role',
      component: () => import('@/views/administration/role/Edit'),
      meta: {
        permissions: [Permissions.backend.administration.role.edit],
        groups: 'role',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី',
            route: { name: 'list-role' }
          },
          {
            name_en: 'Edit',
            name_km: 'កែប្រែព័ត៌'
          }
        ]
      }
    }
  ]
}
