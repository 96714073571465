<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted () {
    if (localStorage.getItem(this.$token)) {
      this.getUserInfo()
      $.ajaxSetup({
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(this.$token)
        }
      })
    }
  }
}
</script>

<style>
.wrapper {
  height: 100vh !important;
}
</style>
