import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

export const facilityRouters = {
  path: 'facility',
  component: () => import('@/views/settings/facility/Index'),
  redirect: 'facility',
  meta: {
    permissions: [Permissions.backend.setting.facility.manage],
    groups: 'facility'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/facility`,
      name: 'list-facility',
      component: () => import('@/views/settings/facility/List'),
      meta: {
        permissions: [Permissions.backend.setting.facility.list],
        groups: 'facility',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Facility',
            name_km: 'ប្រភេទ'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-facility',
      component: () => import('@/views/settings/facility/Create'),
      meta: {
        permissions: [Permissions.backend.setting.facility.store],
        groups: 'facility',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Facility List',
            name_km: 'បញ្ជី',
            route: { name: 'list-facility' }
          },
          {
            name_en: 'Create Facility',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'edit/:uuid',
      name: 'edit-facility',
      component: () => import('@/views/settings/facility/Edit'),
      meta: {
        permissions: [Permissions.backend.setting.facility.edit],
        groups: 'facility',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Facility List',
            name_km: 'បញ្ជី',
            route: { name: 'edit-facility' }
          },
          {
            name_en: 'Edit Facility',
            name_km: 'កែប្រែប្រភេទ'
          }
        ]
      }
    }
  ]
}
