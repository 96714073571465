import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

export const pageRouters = {
  path: 'page',
  component: () => import('@/views/settings/page/Index'),
  redirect: 'page',
  meta: {
    permissions: [Permissions.backend.setting.page.manage],
    groups: 'page'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/page`,
      name: 'list-page',
      component: () => import('@/views/settings/page/List'),
      meta: {
        permissions: [Permissions.backend.setting.page.list],
        groups: 'page',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'page',
            name_km: 'បញ្ជីទំព័រ'
          }
        ]
      }
    },
    {
      path: 'edit/:id',
      name: 'edit-page',
      component: () => import('@/views/settings/page/Edit'),
      meta: {
        permissions: [Permissions.backend.setting.page.list],
        groups: 'page',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'page',
            name_km: 'បញ្ជីទំព័រ'
          },
          {
            name_en: 'edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-page',
      component: () => import('@/views/settings/page/Create'),
      meta: {
        permissions: [Permissions.backend.setting.page.list],
        groups: 'page',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'page',
            name_km: 'បញ្ជីទំព័រ'
          },
          {
            name_en: 'create',
            name_km: 'បង្កើតទំព័រ'
          }
        ]
      }
    }
  ]
}
