import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseAdminModuleUrl } = useBaseRouteModules()

export const permissionRouters = {
  path: 'permission',
  component: () => import('@/views/administration/permission/Index'),
  redirect: 'permission',
  meta: {
    permissions: [Permissions.backend.administration.permission.manage],
    groups: 'permission'
  },
  children: [
    {
      path: `${baseAdminModuleUrl}/permission`,
      name: 'list-permission',
      component: () => import('@/views/administration/permission/List'),
      meta: {
        permissions: [Permissions.backend.administration.permission.list],
        groups: 'permission',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'Permission',
            name_km: 'សិទ្ធិ'
          }
        ]
      }
    }
  ]
}
