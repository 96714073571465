import Index from '@/views/properties/Index'
import List from '@/views/properties/List'
import Show from '@/views/properties/Show'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'

const { basePropertyModuleUrl } = useBaseRouteModules()

export const propertyRoutes = {
  path: 'property',
  name: 'property',
  redirect: 'property',
  component: Index,
  children: [
    {
      path: `${basePropertyModuleUrl}`,
      name: 'list-property',
      component: List,
      meta: {
        groups: 'property',
        breadcrumb: [
          {
            name_en: 'Property',
            name_km: 'អចលនទ្រព្យ'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ចី'
          }
        ]
      }
    },
    {
      path: 'show/:uuid',
      name: 'show-property',
      component: Show,
      meta: {
        groups: 'property',
        breadcrumb: [
          {
            name_en: 'Property',
            name_km: 'អចលនទ្រព្យ'
          },
          {
            name_en: 'Show',
            name_km: 'លម្អិត'
          }
        ]
      }
    }
  ]
}
