<template>
  <div class="property">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t("label.list.property") }}
        </h3>
      </div>
      <div class="box-body">
        <div>
          <table
            id="property-table"
            class="
              table table-hover table-full-width table-striped table-bordered
            "
          >
            <thead>
              <tr>
                <th>{{ $t("table.title") }}</th>
                <th>{{ $t("table.category") }}</th>
                <th>{{ $t("table.price") }}</th>
                <th>{{ $t("table.landlord") }}</th>
                <th>{{ $t("table.status") }}</th>
                <th>{{ $t("table.number_of_visits") }}</th>
                <th>{{ $t("table.address") }}</th>
                <th>{{ $t("table.createdAt") }}</th>
                <th>{{ $t("table.action") }}</th>
              </tr>
            </thead>
            <tbody />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'List',
  metaInfo () {
    return {
      title: this.$t('string.property'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  data () {
    return {
      property: null,
      type: null,
      price: 0,
      category: null,
      province: null,
      district: null,
      commune: null,
      village: null,
      tag: null
    }
  },
  computed: {
    columns () {
      const self = this
      return [
        {
          data: 'title',
          name: 'title',
          width: '250px',
          title: self.$t('table.title')
        },
        {
          data: 'category.name_en',
          name: 'category.name_en',
          title: self.$t('table.category'),
          orderable: false,
          render: (data, type, row) => {
            if (row.category) {
              return row.category['name_' + this.$i18n.locale]
            }
            return this.$t('string.na')
          }
        },
        {
          data: 'price',
          name: 'price',
          title: self.$t('table.price'),
          render: (data) => {
            if (data) {
              return `USD ${parseFloat(data).toFixed(2)}`
            }
            return this.$t('string.na')
          }
        },
        {
          data: 'landlord.full_name',
          name: 'landlord.full_name',
          title: self.$t('table.landlord'),
          orderable: false,
          render: (data) => {
            if (data !== null) {
              return data
            }
            return this.$t('string.na')
          }
        },
        {
          data: 'status',
          name: 'status',
          width: '100px',
          title: self.$t('table.status')
        },
        {
          data: 'number_of_visits',
          name: 'number_of_visits',
          width: '100px',
          title: self.$t('table.number_of_visits')
        },
        {
          data: 'province',
          name: 'province',
          width: '100px',
          title: self.$t('table.province'),
          orderable: false,
          render: (data) => {
            if (data) {
              return data['name_' + this.$i18n.locale]
            }
            return this.$t('string.na')
          }
        },
        {
          data: 'created_at',
          name: 'created_at',
          width: '200px',
          searchable: false,
          title: this.$t('table.createdAt'),
          render: (data) => {
            return this.getDateFormat(data)
          }
        },
        {
          data: 'action',
          name: 'action',
          width: '120px',
          orderable: false,
          searchable: false,
          title: this.$t('table.action')
        }
      ]
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.property.clear()
      this.property.destroy()
      this.property = this.getData()
      this.property.draw(true)
    }
  },
  methods: {
    getData () {
      const self = this
      return $('#property-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        length: 10,
        ajax: {
          method: 'POST',
          url: self.apiUrl + '/api/backend/property/datatable',
          error: (xhr) => {
            self.onResponseError(xhr)
          },
          data: (d) => {
            d.delete = self.$t('label.delete')
            d.show = self.$t('label.show')
            d.language = self.$i18n.locale || 'en'
          }
        },
        columns: self.columns,
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        },
        order: [[7, 'desc']]
      })
    },
    fetchLocation () {
      this.$isLoading(true)
      this.$axios
        .post('/api/backend/location/get', this.type)
        .then((response) => {
          if (response.data && response.data.data) {
            this.location = response.data.data
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    loadAction () {
      const self = this
      this.clearEventHandler(['.btn-delete-property'])
      $(function () {
        $(document).on('click', '.btn-delete-property', function (e) {
          e.preventDefault()
          self.deleteProperty($(this).attr('data-uuid'))
        })
      })
      $(document).on('click', '.btn-show-user', function (e) {
        e.preventDefault()
        self.$router.push({
          name: 'show-property',
          params: {
            uuid: $(this).attr('data-uuid')
          }
        })
      })
    },
    deleteProperty (uuid) {
      const self = this
      this.$swal({
        title: self.$t('label.swal.title'),
        text: self.$t('label.swal.desc'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: self.$t('label.swal.no'),
        confirmButtonText: self.$t('label.swal.yes')
      }).then((result) => {
        if (result.value) {
          this.$axios
            .post('/api/backend/property/delete', {
              uuid: uuid
            })
            .then(() => {
              self.showSwalSuccess()
              self.property.draw(true)
            })
            .catch((error) => {
              this.onResponseError(error)
            })
        }
      })
    }
  },
  mounted () {
    this.property = this.getData()
    this.loadAction()
  },
  beforeDestroy () {
    this.property.clear()
    this.property.destroy()
  }
}
</script>

<style scoped></style>
