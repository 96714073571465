import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'

const { baseSettingModuleUrl } = useBaseRouteModules()

export const categoryRouters = {
  path: 'category',
  component: () => import('@/views/settings/category/Index'),
  redirect: 'category',
  meta: {
    permissions: [Permissions.backend.setting.category.manage],
    groups: 'category'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/category`,
      name: 'list-category',
      component: () => import('@/views/settings/category/List'),
      meta: {
        permissions: [Permissions.backend.setting.category.list],
        groups: 'category',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Category',
            name_km: 'ប្រភេទ'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-category',
      component: () => import('@/views/settings/category/Create'),
      meta: {
        permissions: [Permissions.backend.setting.category.store],
        groups: 'category',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី',
            route: { name: 'list-category' }
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'edit/:uuid',
      name: 'edit-category',
      component: () => import('@/views/settings/category/Edit'),
      meta: {
        permissions: [Permissions.backend.setting.category.edit],
        groups: 'category',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី',
            route: { name: 'list-category' }
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    }
  ]
}
