import { genderRouters } from './gender'
import { categoryRouters } from './category'
import { tagRouters } from './tag'
import Permissions from '../../permissions'
import { provinceRouters } from './province'
import { districtRouters } from './district'
import { communeRouters } from './commune'
import { villageRouters } from './village'
import { pageRouters } from './page'
import { sub_categoryRouters } from './subcategory'
import { facilityRouters } from './facility'
import { logsViewerRouters } from '@/routers/settings/logs'

export const settingRouters = {
  path: 'settings',
  name: 'settings',
  component: () => import('@/views/settings/Index'),
  redirect: 'gender',
  meta: {
    permissions: [Permissions.backend.setting.manage]
  },
  children: [
    genderRouters,
    categoryRouters,
    sub_categoryRouters,
    tagRouters,
    provinceRouters,
    districtRouters,
    communeRouters,
    villageRouters,
    pageRouters,
    facilityRouters,
    logsViewerRouters
  ]
}
