import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

export const communeRouters = {
  path: 'commune',
  component: () => import('@/views/settings/commune/Index'),
  redirect: 'commune',
  meta: {
    permissions: [Permissions.backend.setting.commune.manage],
    groups: 'commune'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/commune`,
      name: 'list-commune',
      component: () => import('@/views/settings/commune/List'),
      meta: {
        permissions: [Permissions.backend.setting.commune.list],
        groups: 'commune',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'commune',
            name_km: 'បញ្ជីឈ្មោះឃុំ/សង្កាត់'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-commune',
      component: () => import('@/views/settings/commune/Create'),
      meta: {
        permissions: [Permissions.backend.setting.commune.create],
        groups: 'commune',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Commune List',
            name_km: 'បញ្ជីឈ្មោះឃុំ/សង្កាត់',
            route: { name: 'list-commune' }
          },
          {
            name_en: 'Create commune',
            name_km: 'បញ្ជីឈ្មោះឃុំ/សង្កាត់'
          }
        ]
      }
    },
    {
      path: 'edit/:id',
      name: 'edit-commune',
      component: () => import('@/views/settings/commune/Edit'),
      meta: {
        permissions: [Permissions.backend.setting.commune.edit],
        groups: 'commune',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Commune List',
            name_km: 'បញ្ជីឈ្មោះឃុំ/សង្កាត់',
            route: { name: 'edit-commune' }
          },
          {
            name_en: 'Edit Commune',
            name_km: 'កែសំរួលឈ្មោះឃុំ/សង្កាត់'
          }
        ]
      }
    }
  ]
}
