import Index from '../views/tenant/Index'
import List from '../views/tenant/List'
import Show from '../views/tenant/Show'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'

const { baseTenantModuleUrl } = useBaseRouteModules()

export const tenantRoutes = {
  path: 'tenant',
  name: 'tenant',
  redirect: 'tenant',
  component: Index,
  children: [
    {
      path: `${baseTenantModuleUrl}`,
      name: 'list-tenant',
      component: List,
      meta: {
        groups: 'tenant',
        breadcrumb: [
          {
            name_en: 'Tenant',
            name_km: 'អ្នកជួល'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ចី'
          }
        ]
      }
    },
    {
      path: 'show/:uuid',
      name: 'show-tenant',
      component: Show,
      meta: {
        groups: 'tenant',
        breadcrumb: [
          {
            name_en: 'Tenant',
            name_km: 'អ្នកជួល'
          },
          {
            n0ame_en: 'Show',
            name_km: 'លម្អិត'
          }
        ]
      }
    }
  ]
}
