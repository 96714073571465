export const errorsRouters = {
  path: '*',
  name: 'errors',
  component: () => import('@/layouts/Primary'),
  meta: {
    noAuth: true
  },
  children: [
    {
      path: '*',
      component: () => import('@/views/errors/Error'),
      meta: {
        breadcrumb: [
          {
            name_en: 'Error',
            name_km: 'ទំព័ររកមិនឃើញ'
          },
          {
            name_en: '404',
            name_km: '៤០៤'
          }
        ]
      }
    }
  ]
}
