<template xmlns:text-align="http://www.w3.org/1999/xhtml">
  <div class="property">
    <div class="row">
      <div class="col-md-4">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("label.showProperty") }}
            </h3>
            <div class="box-tools">
              <ButtonBack />
            </div>
          </div>
          <div
            v-if="property"
            class="box-body"
          >
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td class="title">
                      {{ $t("table.landlord") }}
                    </td>
                    <td v-if="property.landlord">
                      {{ property.landlord.full_name }}
                    </td>
                    <td v-else>
                      -
                    </td>
                  </tr>
                  <tr>
                    <td class="title">
                      {{ $t("table.title") }}
                    </td>
                    <td>{{ property.title }}</td>
                  </tr>
                  <tr>
                    <td class="title">
                      {{ $t("table.category") }}
                    </td>
                    <td v-if="property.category">
                      {{ property.category["name_" + $i18n.locale] }}
                    </td>
                    <td v-else>
                      -
                    </td>
                  </tr>
                  <tr>
                    <td class="title">
                      {{ $t("table.status") }}
                    </td>
                    <td>
                      <span class="label label-info">{{
                        property.status["label_" + $i18n.locale]
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="title">
                      {{ $t("table.description") }}
                    </td>
                    <td>{{ property.description }}</td>
                  </tr>
                  <tr>
                    <td class="title">
                      {{ $t("table.createdAt") }}
                    </td>
                    <td>{{ this.getDateFormat(property.created_at) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t("label.map") }}
            </h3>
          </div>
          <div class="box-body">
            <GmapMap
              v-for="(m, index) in markers"
              :key="index"
              :center="m.position"
              :clickable="false"
              :draggable="false"
              :zoom="8"
              map-type-id="terrain"
              style="width: 100%; height: 300px"
            >
              <GmapMarker
                v-for="(marker, key) in markers"
                :key="key"
                :clickable="true"
                :position="marker.position"
                @click="center = marker.position"
              />
            </GmapMap>
            <p
              v-if="property"
              style="margin-top: 20px"
            >
              {{
                $t("label.location") +
                  ": " +
                  property["location_" + $i18n.locale]
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs">
            <li class="active">
              <a
                aria-expanded="false"
                data-toggle="tab"
                href="#tab_image"
              >{{
                $t("label.images")
              }}</a>
            </li>
            <li class="">
              <a
                aria-expanded="false"
                data-toggle="tab"
                href="#tab_request"
              >{{
                $t("label.request")
              }}</a>
            </li>
            <li class="">
              <a
                aria-expanded="false"
                data-toggle="tab"
                href="#tab_action"
              >{{
                $t("label.activity")
              }}</a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              id="tab_request"
              class="tab-pane"
            >
              <div class="row">
                <div
                  v-if="$i18n.locale == 'km'"
                  class="col-md-12"
                >
                  <ul
                    class="timeline"
                    v-html="renting_km"
                  />
                </div>
                <div
                  v-if="$i18n.locale == 'en'"
                  class="col-md-12"
                >
                  <ul
                    class="timeline"
                    v-html="renting_en"
                  />
                </div>
              </div>
            </div>
            <div
              id="tab_action"
              class="tab-pane"
            >
              <h3
                v-if="$i18n.locale == 'en'"
                style="text-align: center; margin-bottom: 30px"
              >
                Doesn't has any action yet!
              </h3>
              <h3
                v-if="$i18n.locale == 'km'"
                style="text-align: center; margin-bottom: 30px"
              >
                មិនទាន់មានសកម្មភាពនៅឡើយ
              </h3>
            </div>

            <div
              id="tab_image"
              class="tab-pane active"
            >
              <viewer :images="images">
                <div
                  v-for="(src, key) in images"
                  class="container-image"
                  :key="key"
                >
                  <img
                    :src="getSrc(src.src)"
                    class="image-style"
                    alt=""
                  >
                </div>
              </viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBack from '@/components/ButtonBack'

export default {
  name: 'Show',
  components: { ButtonBack },
  metaInfo () {
    return {
      title: this.$t('string.detail'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  data () {
    return {
      property: null,
      request: null,
      renting_en: null,
      renting_km: null,
      path: process.env.VUE_APP_API,
      images: null,
      markers: [
        {
          position: {
            lat: 11.567279,
            lng: 104.895771
          }
        }
      ]
    }
  },
  methods: {
    fetchPropertyByUuid () {
      this.$isLoading(true)
      this.$axios
        .post('/api/backend/property/show', {
          uuid: this.$route.params.uuid
        })
        .then((response) => {
          this.property = response.data.data.property
          this.images = this.property.images
          this.request = response.data.data.request
          this.renting_en = response.data.data.action_en
          this.renting_km = response.data.data.action_km
          this.markers[0].position.lat = this.property.lat
          this.markers[0].position.lng = this.property.lng
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  },
  mounted () {
    this.fetchPropertyByUuid()
  }
}
</script>

<style scoped>
.image-style {
  width: calc(33% - 10px);
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}

.container-image {
  display: inline;
}

.title {
  width: 30%;
}
</style>
