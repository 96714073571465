<template>
  <div class="row">
    <LogsStats />
    <LogsList />
  </div>
</template>
<script>
import LogsStats from '@/views/settings/logsViewer/LogsStats'
import LogsList from '@/views/settings/logsViewer/LogsList'

export default {
  name: 'List',
  components: { LogsList, LogsStats },
  metaInfo () {
    return {
      title: this.$t('logs_viewer.menu'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  }
}
</script>

<style scoped></style>
