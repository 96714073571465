import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseAccountModuleUrl } = useBaseRouteModules()

export const accountRouters = {
  path: 'account',
  name: 'account',
  component: () => import('@/views/account/Layout'),
  redirect: 'account',
  meta: {
    groups: 'account'
  },
  children: [
    {
      path: `${baseAccountModuleUrl}/account`,
      name: 'profile',
      component: () => import('@/views/account/Index')
    }
  ]
}
