import Vue from 'vue'
import Router from 'vue-router'
import { primaryRouter } from './routers/primary'
import { secondaryRouters } from './routers/secondary'
import { errorsRouters } from './routers/errors'
import { unauthorizedRouters } from './routers/unauthorized'
import axios from './axios'

Vue.use(Router)

const getRouter = (store) => {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      primaryRouter,
      secondaryRouters,
      errorsRouters,
      unauthorizedRouters
    ]
  })

  const handleUserData = (to, from, next, callback) => {
    /**
     * If no user data, we need to fetch it first
     */
    if (!store.state.user.data) {
      const _token = localStorage.getItem(process.env.VUE_APP_TOKEN)
      /**
       * Token must be defined in localstorage in order to fetch user data,
       * Otherwise, it will redirect to login page
       */
      if (_token) {
        axios.post('/api/backend/user/get-roles-and-permissions')
          .then((response) => {
            const result = response.data.data
            store.dispatch('user/setUser', { user: result.user })
            // store roles
            store.dispatch('user/setRoles', result.roles)
            // store permission
            store.dispatch('user/setPermissions', result.permissions)
            callback()
          })
          .catch((error) => {
            if (
              error.response === undefined ||
              (error.response && error.response.status === 401)
            ) {
              localStorage.clear()
              store.dispatch('user/clearUser')
              next({ name: 'login' })
            } else {
              next()
            }
          })
      } else {
        localStorage.clear()
        store.dispatch('user/clearUser')
        next({ name: 'login' })
      }
    } else {
      callback()
    }
  }

  router.beforeEach((to, from, next) => {
    if (document.querySelector('.wrapper')) {
      document.querySelector('.wrapper').scrollTo(0, 0)
    }
    /**
     * User should go to login page when already login
     */
    if (
      to.name === 'login' &&
      localStorage.getItem(process.env.VUE_APP_TOKEN)
    ) {
      next({ name: 'admin', replace: true })
      return
    }

    /**
     * Need to defined noAuth meta on unprotected route
     * Any unprotected route, no need to check, just proceed
     */
    if (to.meta.noAuth) {
      next()
      return
    }

    handleUserData(to, from, next, () => {
      const userRoles = store.state.user.data.roles || []
      let rolesQualified = true
      if (!store.getters['user/isAdmin']) {
        for (const route of to.matched) {
          const qualifiedAtRoute = route.meta.roles
            ? userRoles.some((role) => route.meta.roles.includes(role))
            : true
          if (!qualifiedAtRoute) {
            rolesQualified = false
            break
          }
        }
      }

      let permissionsQualified = true
      if (rolesQualified) {
        const userPermissions = store.state.user.data.permissions || []
        if (!store.getters['user/isAdmin']) {
          for (const route of to.matched) {
            const qualifiedAtRoute = route.meta.permissions
              ? userPermissions.some((permission) =>
                route.meta.permissions.includes(permission)
              )
              : true
            if (!qualifiedAtRoute) {
              permissionsQualified = false
              break
            }
          }
        }
      }

      if (rolesQualified && permissionsQualified) {
        next()
      } else {
        next({ name: 'unauthorized' })
      }
    })
  })

  const originalPush = router.push
  router.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalPush.call(this, location, onResolve, onReject)
    }

    return originalPush.call(this, location).catch((err) => {
      if (Router.isNavigationFailure(err)) {
        return err
      }

      return Promise.reject(err)
    })
  }

  return router
}
export default getRouter
