import '@/libraries/stylesheets'
import '@/libraries/scripts'
import '@/assets/css/tailwind.css'

import Vue from 'vue'
import axios from '@/axios'
import App from '@/App.vue'
import getRouter from '@/router'
import store from '@/store'
import { i18n } from '@/i18n'
import '@/plugins'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$base_api = process.env.VUE_APP_API
Vue.prototype.$token = process.env.VUE_APP_TOKEN
Vue.prototype.$nbPerPage = process.env.VUE_APP_NUMBER_PER_PAGE

const router = getRouter(store)

const vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

export default vm
