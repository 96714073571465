import { dashboardRoutes } from './dashboard'
import { settingRouters } from './settings'
import { accountRouters } from './account'
import Permissions from '../permissions'
import { propertyRoutes } from './property'
import { landlordRoutes } from './landlord'
import { tenantRoutes } from './tenant'
import { administrationRouters } from '@/routers/administrations'

export const primaryRouter = {
  path: '/admin',
  name: 'primary',
  component: () => import('@/layouts/Primary'),
  redirect: '/admin/dashboard',
  meta: {
    permissions: [Permissions.backend.manage]
  },
  children: [
    dashboardRoutes,
    settingRouters,
    administrationRouters,
    accountRouters,
    propertyRoutes,
    landlordRoutes,
    tenantRoutes
  ]
}
