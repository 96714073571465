import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

export const tagRouters = {
  path: 'tag',
  component: () => import('@/views/settings/tag/Index'),
  redirect: 'tag',
  meta: {
    permissions: [Permissions.backend.setting.tag.manage],
    groups: 'tag'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/tag`,
      name: 'list-tag',
      component: () => import('@/views/settings/tag/List'),
      meta: {
        permissions: [Permissions.backend.setting.tag.list],
        groups: 'tag',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Tag',
            name_km: 'ពាក្យទាក់ទង'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-tag',
      component: () => import('@/views/settings/tag/Create'),
      meta: {
        permissions: [Permissions.backend.setting.tag.create],
        groups: 'tag',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី',
            route: { name: 'list-tag' }
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'edit/:uuid',
      name: 'edit-tag',
      component: () => import('@/views/settings/tag/Edit'),
      meta: {
        permissions: [Permissions.backend.setting.tag.edit],
        groups: 'tag',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជីប្រភេទ',
            route: { name: 'list-tag' }
          },
          {
            name_en: 'Edit',
            name_km: 'កែសំរួល'
          }
        ]
      }
    }
  ]
}
