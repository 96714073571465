import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

export const provinceRouters = {
  path: 'province',
  component: () => import('@/views/settings/province/Index'),
  redirect: 'province',
  meta: {
    permissions: [Permissions.backend.setting.province.manage],
    groups: 'province'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/province`,
      name: 'list-province',
      component: () => import('@/views/settings/province/List'),
      meta: {
        permissions: [Permissions.backend.setting.province.list],
        groups: 'province',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Province',
            name_km: 'បញ្ជីឈ្មោះខេត្ត'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-province',
      component: () => import('@/views/settings/province/Create'),
      meta: {
        permissions: [Permissions.backend.setting.province.create],
        groups: 'province',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជីឈ្មោះខេត្ត',
            route: { name: 'list-province' }
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'edit/:id',
      name: 'edit-province',
      component: () => import('@/views/settings/province/Edit'),
      meta: {
        permissions: [Permissions.backend.setting.province.edit],
        groups: 'province',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជីឈ្មោះខេត្ត',
            route: { name: 'list-province' }
          },
          {
            name_en: 'Edit',
            name_km: 'កែសំរួល'
          }
        ]
      }
    }
  ]
}
