import Logs from '@/views/settings/logsViewer/Index'
import List from '@/views/settings/logsViewer/List'
import Show from '@/views/settings/logsViewer/Show'
import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

const pLogsViewer = Permissions.backend.setting.logsViewer

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'logs-viewer',
    header: {
      en: 'Logs Viewer',
      km: 'កំណត់ហេតុបញ្ហា'
    },
    breadcrumb: [
      {
        name_en: 'Settings',
        name_km: 'ការកំណត់'
      },
      {
        name_en: 'Logs Viewer',
        name_km: 'កំណត់ហេតុបញ្ហា'
      },
      link
    ]
  }
}

export const logsViewerRouters = {
  path: 'logs-viewer',
  component: Logs,
  redirect: 'logs-viewer',
  meta: {
    permissions: [pLogsViewer.manage],
    groups: 'logs-viewer'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/logs-viewer`,
      name: 'list-logs-viewer',
      component: List,
      meta: getMeta([pLogsViewer.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'show/:date',
      name: 'show-logs-viewer',
      component: Show,
      meta: getMeta([pLogsViewer.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    }
  ]
}
