<template>
  <div class="col-md-12 mb-3">
    <div class="row">
      <div
        class="col-md-3"
        v-if="chartData"
      >
        <x-chart-doughnut
          :chart-data="chartData"
          :options="{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: 'bottom',
            },
          }"
          chart-id="logs-chart"
        />
      </div>

      <div class="col-md-9">
        <section class="box-body">
          <div class="row">
            <template v-for="(item, level) in percents">
              <div
                :key="level.name"
                class="col-md-4"
              >
                <div
                  :class="`info-box level level-${
                    item['count'] === 0 ? 'empty' : level
                  }`"
                >
                  <div class="info-box-icon">
                    <span v-html="item.icon" />
                  </div>

                  <div class="info-box-content">
                    <span class="box-text">{{ item["name"] }}</span>
                    <span class="box-number">
                      {{ item["count"] }} entries - {{ item["percent"] }} %
                    </span>
                    <div
                      class="progress"
                      style="height: 3px"
                    >
                      <div
                        :style="`width: ${item['percent']}%`"
                        class="progress-bar"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogsStats',
  data () {
    return {
      chartData: null,
      percents: null
    }
  },
  methods: {
    getStats () {
      this.$isLoading(true)
      this.$axios
        .post('/api/backend/logs-viewer/dashboard')
        .then(({ data }) => {
          if (data.data) {
            this.chartData = data.data.chartData
            this.percents = data.data.percents
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  },
  mounted () {
    this.getStats()
  }
}
</script>

<style scoped>
.stack-content {
  padding: 8px;
  color: #ae0e0e;
  font-family: consolas, Menlo, Courier, monospace;
  font-size: 12px;
  font-weight: 400;
  white-space: pre-line;
}

.info-box.level {
  display: block;
  padding: 0;
  margin-bottom: 15px;
  min-height: 70px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.info-box.level .info-box-text,
.info-box.level .info-box-number,
.info-box.level .info-box-icon > i {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.info-box.level .info-box-text {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box.level .info-box-content {
  padding: 5px 10px;
  margin-left: 70px;
}

.info-box.level .info-box-number {
  display: block;
  font-weight: bold;
  font-size: 18px;
}

.info-box.level .info-box-icon {
  border-radius: 2px 0 0 2px;
  display: block;
  float: left;
  height: 70px;
  width: 70px;
  text-align: center;
  font-size: 40px;
  line-height: 70px;
  background: rgba(0, 0, 0, 0.2);
}

.info-box.level .progress {
  background: rgba(0, 0, 0, 0.2);
  margin: 5px -10px 5px -10px;
  height: 2px;
}

.info-box.level .progress .progress-bar {
  background: #fff;
}

.info-box.level-empty {
  opacity: 0.6;
  filter: grayscale(1);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transition-property: -webkit-filter, opacity;
  -moz-transition-property: -moz-filter, opacity;
  -o-transition-property: filter, opacity;
  transition-property: -webkit-filter, -moz-filter, -o-filter, filter, opacity;
}

.info-box.level-empty:hover {
  opacity: 1;
  filter: grayscale(0);
}

.level {
  padding: 2px 6px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  font-size: 0.9em;
  font-weight: 600;
}

.badge.level-all,
.badge.level-emergency,
.badge.level-alert,
.badge.level-critical,
.badge.level-error,
.badge.level-warning,
.badge.level-notice,
.badge.level-info,
.badge.level-debug,
.level,
.level i,
.info-box.level-all,
.info-box.level-emergency,
.info-box.level-alert,
.info-box.level-critical,
.info-box.level-error,
.info-box.level-warning,
.info-box.level-notice,
.info-box.level-info,
.info-box.level-debug {
  color: #fff;
}

.label-env {
  font-size: 0.85em;
}

.badge.level-all,
.level.level-all,
.info-box.level-all {
  background-color: #8a8a8a;
}

.badge.level-emergency,
.level.level-emergency,
.info-box.level-emergency {
  background-color: #b71c1c;
}

.badge.level-alert,
.level.level-alert,
.info-box.level-alert {
  background-color: #d32f2f;
}

.badge.level-critical,
.level.level-critical,
.info-box.level-critical {
  background-color: #f44336;
}

.badge.level-error,
.level.level-error,
.info-box.level-error {
  background-color: #ff5722;
}

.badge.level-warning,
.level.level-warning,
.info-box.level-warning {
  background-color: #ff9100;
}

.badge.level-notice,
.level.level-notice,
.info-box.level-notice {
  background-color: #4caf50;
}

.badge.level-info,
.level.level-info,
.info-box.level-info {
  background-color: #1976d2;
}

.badge.level-debug,
.level.level-debug,
.info-box.level-debug {
  background-color: #90caf9;
}

.badge.level-empty,
.level.level-empty {
  background-color: #d1d1d1;
}

.badge.label-env,
.label.label-env {
  background-color: #6a1b9a;
}
</style>
