import Vue from 'vue'

const state = {
  data: null,
  administrators: [process.env.VUE_APP_ROLE_ADMIN, 'admin']
}

const getters = {
  getUser: (state) => {
    return state.data
  },
  isAdmin: (state) => {
    if (state.data) {
      return state.data.roles.some((role) =>
        state.administrators.includes(role)
      )
    } else {
      return false
    }
  },
  userRoles: (state) => state.data ? state.data.roles : null,
  userPermissions: (state) => state.data ? state.data.permissions : null
}

const mutations = {
  setUser (state, data) {
    if (!state.data) {
      Vue.set(state, 'data', {})
    }
    for (const attribute of Object.keys(data.user)) {
      if (!['roles', 'permissions'].includes(attribute)) {
        Vue.set(state.data, attribute, data.user[attribute])
      }
    }
  },
  setRoles (state, roles) {
    if (state.data) {
      state.data.roles = roles
    } else {
      console.log('User data not yet defined')
    }
  },
  setPermissions (state, permissions) {
    if (state.data) {
      state.data.permissions = permissions
    } else {
      console.log('User data not yet defined')
    }
  },

  clearUser (state) {
    state.data = null
  }
}

const actions = {
  setUser ({ commit }, data) {
    commit('setUser', data)
  },
  setRoles ({ commit }, data) {
    commit('setRoles', data)
  },
  setPermissions ({ commit }, data) {
    commit('setPermissions', data)
  },
  clearUser ({ commit }) {
    commit('clearUser')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
