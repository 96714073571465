import { mapGetters } from 'vuex'

const utils = {
  install (Vue) {
    Vue.mixin({
      data () {
        return {
          administrators: [process.env.VUE_APP_ROLE_ADMIN, 'admin'],
          apiUrl: process.env.VUE_APP_API
        }
      },
      computed: {
        ...mapGetters('user', ['userRoles', 'userPermissions']),
        baseUrl () {
          return process.env.VUE_APP_API
        }
      },
      methods: {
        getDateFormat (date, format = process.env.VUE_APP_DATE_FORMAT) {
          if (this.$moment(date).isValid()) {
            return this.$moment(date).locale('en').format(format)
          }
          return this.$t('string.na')
        },
        createObjectURL (media) {
          if (media instanceof File) {
            return URL.createObjectURL(media)
          } else {
            return this.getSrc(media)
          }
        },
        getSrc (url) {
          if (!url) {
            return ''
          } else if (url.startsWith('http')) {
            return url
          } else {
            if (url.startsWith('/')) {
              return process.env.VUE_APP_API + url
            } else {
              return process.env.VUE_APP_API + '/' + url
            }
          }
        },
        onConfirm (options) {
          const self = this
          return new Promise((resolve, reject) => {
            this.$swal
              .fire(
                Object.assign(
                  {
                    title: self.$t('label.swal.title'),
                    text: self.$t('label.swal.desc'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: self.$t('label.swal.no'),
                    confirmButtonText: self.$t('label.swal.yes')
                  },
                  options
                )
              )
              .then((result) => {
                if (result.value) {
                  resolve({
                    accept: true
                  })
                } else {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  reject({
                    accept: false
                  })
                }
              })
          })
        },
        getName (object) {
          if (object) {
            if (object.hasOwnProperty('name_' + this.$i18n.locale)) {
              return object['name_' + this.$i18n.locale]
            } else if (object.hasOwnProperty('name')) {
              return object.name
            } else if (object.hasOwnProperty('title')) {
              return object.title
            }
          }
          return this.$t('label.na')
        },
        showSwalSuccess () {
          this.$swal({
            title: this.$t('label.swal.deleteLabel'),
            text: this.$t('label.swal.success'),
            type: 'success',
            confirmButtonText: this.$t('button.ok')
          })
        },
        showToastr () {
          this.$toastr(
            'success',
            this.$t('string.theRequestHaveBeenProcessed'),
            this.$t('string.success')
          )
        },
        onResponseError (error) {
          if (error.statusText) {
            this.$toastr('error', error.statusText, this.$t('string.error'))
          } else if (error.response) {
            if (error.response.status === 403) {
              this.$toastr(
                'error',
                error.response.data.message,
                this.$t('string.error')
              )
            } else {
              try {
                if (
                  error.response.data.message &&
                  error.response.data.message['message_' + this.$i18n.locale]
                ) {
                  this.$toastr(
                    'error',
                    error.response.data.message['message_' + this.$i18n.locale],
                    this.$t('string.error')
                  )
                } else {
                  this.$toastr(
                    'error',
                    error.response.statusText,
                    this.$t('string.error')
                  )
                }
              } catch (e) {
                console.log('got error')
                this.$toastr(
                  'error',
                  this.$t('string.somethingWentWrong'),
                  this.$t('string.error')
                )
              }
            }
          }
        },
        getUserInfo () {
          const self = this
          this.$axios.post('/api/backend/user/get-roles-and-permissions')
            .then(async ({ data }) => {
              if (data.data) {
                const { roles, permissions, user } = data.data
                await self.$store.dispatch('user/setUser', { user })
                self.$store.commit('user/setRoles', roles)
                self.$store.commit('user/setPermissions', permissions)
              }
            })
            .catch((error) => {
              self.onResponseError(error)
            })
        },
        clearEventHandler (classNames) {
          classNames.map((className) => {
            return $(document).off('click', className)
          })
        },
        can (permissions) {
          if (
            !permissions ||
            permissions.length === 0 ||
            this.$store.getters['user/isAdmin']
          ) {
            return true
          }

          if (!this.userPermissions) {
            return false
          }

          return this.userPermissions.some((permission) =>
            permissions.includes(permission)
          )
        },
        hasRoles (roles) {
          return (
            this.$store.getters['user/isAdmin'] || this.onlyHasRoles(roles)
          )
        },
        onlyHasRoles (roles) {
          if (!roles || roles.length === 0) {
            return true
          }

          if (!this.userRoles) {
            return false
          }

          return this.userRoles.some((role) => roles.includes(role))
        },
        isAdmin () {
          if (!this.userRoles) {
            return false
          }
          return this.userRoles.some((role) =>
            this.administrators.includes(role)
          )
        }
      }
    })
  }
}

export default utils
