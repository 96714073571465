import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

export const districtRouters = {
  path: 'district',
  component: () => import('@/views/settings/district/Index'),
  redirect: 'district',
  meta: {
    permissions: [Permissions.backend.setting.district.manage],
    groups: 'district'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/district`,
      name: 'list-district',
      component: () => import('@/views/settings/district/List'),
      meta: {
        permissions: [Permissions.backend.setting.district.list],
        groups: 'district',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'district',
            name_km: 'បញ្ជីឈ្មោះស្រុក'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-district',
      component: () => import('@/views/settings/district/Create'),
      meta: {
        permissions: [Permissions.backend.setting.district.create],
        groups: 'district',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'District List',
            name_km: 'បញ្ជីឈ្មោះស្រុក',
            route: { name: 'list-district' }
          },
          {
            name_en: 'Create district',
            name_km: 'បញ្ជីឈ្មោះស្រុក'
          }
        ]
      }
    },
    {
      path: 'edit/:id',
      name: 'edit-district',
      component: () => import('@/views/settings/district/Edit'),
      meta: {
        permissions: [Permissions.backend.setting.district.edit],
        groups: 'district',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'District List',
            name_km: 'បញ្ជីឈ្មោះស្រុក',
            route: { name: 'edit-district' }
          },
          {
            name_en: 'Edit District',
            name_km: 'កែសំរួលឈ្មោះស្រុក'
          }
        ]
      }
    }
  ]
}
