<template>
  <button
    :class="`btn btn-default btn-${size}`"
    @click="hasHistory() ? $router.go(-1) : $router.push('/')"
  >
    <i class="fa fa-arrow-left" /> {{ $t("button.back") }}
  </button>
</template>

<script>
export default {
  name: 'ButtonBack',
  props: {
    size: {
      type: String,
      default: 'sm'
    }
  },
  methods: {
    hasHistory () {
      return window.history.length > 2
    }
  }
}
</script>

<style scoped></style>
