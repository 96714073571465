import Vue from 'vue'
import VueI18n from 'vue-i18n'

const files = require.context('./locale/', false, /\.json$/i)

// Ready translated locale messages
const messages = {}

files.keys().forEach((key) => {
  const lang = key.split('/')[1].split('.')[0]
  messages[lang] = files(key)
})

Vue.use(VueI18n)

// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || process.env.VUE_APP_LOCALE, // set locale
  messages // set locale messages
})
