import Index from '@/views/landlords/Index'
import List from '@/views/landlords/List'
import Show from '@/views/landlords/Show'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'

const { baseLandlordModuleUrl } = useBaseRouteModules()

export const landlordRoutes = {
  path: 'landlord',
  name: 'landlord',
  redirect: 'landlord',
  component: Index,
  children: [
    {
      path: `${baseLandlordModuleUrl}`,
      name: 'list-landlord',
      component: List,
      meta: {
        groups: 'landlord',
        breadcrumb: [
          {
            name_en: 'Landlord',
            name_km: 'ម្ចាស់អចលនទ្រព្យ'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ចី'
          }
        ]
      }
    },
    {
      path: 'show/:uuid',
      name: 'show-landlord',
      component: Show,
      meta: {
        groups: 'landlord',
        breadcrumb: [
          {
            name_en: 'Landlord',
            name_km: 'ម្ចាស់អចលនទ្រព្យ'
          },
          {
            name_en: 'Show',
            name_km: 'លម្អិត'
          }
        ]
      }
    }
  ]
}
