import axios from 'axios'
import router from './router'

axios.defaults.baseURL = process.env.VUE_APP_API

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Headers':
    'Origin, X-Requested-With, Content-Type, Accept',
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

if (localStorage.getItem(process.env.VUE_APP_TOKEN)) {
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem(process.env.VUE_APP_TOKEN)
  $.ajaxSetup({
    headers: {
      Accept: 'application/json',
      Authorization:
        'Bearer ' + localStorage.getItem(process.env.VUE_APP_TOKEN)
    }
  })
}

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error

    if (error.response.status !== 401) {
      return Promise.reject(error)
    }

    if (error.config.url === '/api/auth/backend/refresh-token') {
      localStorage.clear()
      router.push({ name: 'login' })
      return Promise.reject(error)
    }

    return axios.post('/api/auth/backend/refresh-token', {
      rft: localStorage.getItem(process.env.VUE_APP_REFRESH_TOKEN)
    }).then((response) => {
      const result = response.data.data
      const config = error.config
      localStorage.setItem(process.env.VUE_APP_TOKEN, result.access_token)
      localStorage.setItem(
        process.env.VUE_APP_REFRESH_TOKEN,
        result.refresh_token
      )
      config.headers.Authorization = `Bearer ${result.access_token}`
      return new Promise((resolve, reject) => {
        axios
          .request(config)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }).catch((error) => {
      Promise.reject(error)
    })
  }
)

export default axios
